body {
  font-family: "Hiragino Kaku Gothic ProN W3", "Hiragino Kaku Gothic Pro",
    Meiryo, Osaka, "MS PGothic", sans-serif;
}

input,
textarea,
select,
button {
  font-family: "Hiragino Kaku Gothic ProN W3", "Hiragino Kaku Gothic Pro",
    Meiryo, Osaka, "MS PGothic", sans-serif;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* (424 + 180)px */
@media only screen and (min-width: 725px) {
  #root {
  }
}
