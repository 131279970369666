@charset "UTF-8";
#lnln-root .content {
  background-color: #f66;
}
/* lp common
================================================== */

#lnln-root .header--border-bottom {
  border-bottom: 1px solid #c8c8c8;
}

#lnln-root .section-01 {
  padding: 22px 17px 30px;
}

#lnln-root .section-02 {
  padding: 0 13px 40px;
}

#lnln-root .section-03 {
  padding: 15px 17px 26px;
}

#lnln-root .section-04 {
  padding: 28px 17px 16px;
}

#lnln-root .section-05 {
  padding: 15px 26.5px 36px;
}

#lnln-root .section-06 {
  padding: 0 13px 32px;
}

#lnln-root .text-link {
  color: #ff9096;
}

#lnln-root .lead-01 {
  font-size: 14px;
  line-height: 1.7858;
  color: #54544f;
}

#lnln-root .lead-01 a {
  color: #ff9096;
}

#lnln-root .em-01 {
  color: #ff9096;
}

#lnln-root .note-01 {
  font-size: 11px;
  line-height: 1.85;
  color: #b7b7b7;
}

#lnln-root .feat {
  max-width: 695px;
  margin-left: auto;
  margin-right: auto;
}


#lnln-root .feat-title {
  margin-bottom: 0;
}

#lnln-root .feat-title img {
  margin: 0;
  vertical-align: bottom;
}


#lnln-root .feat-inner-01 {
  padding: 18px 13.5px;
}

#lnln-root .feat-inner-02 {
  padding: 18px 13.5px 11px 13.5px;
}

#lnln-root .m-t-18 {
  margin-top: 18px;
}

#lnln-root .no-list-style {
  list-style: none;
  margin: auto;
  padding: 0;
}

#lnln-root .app-banners {
  padding: 0 4px;
  text-align: center;
}

#lnln-root .app-banners-catch {
  margin-bottom: 16px;
  padding: 0 12px;
  text-align: center;
}

#lnln-root .app-banners-list {
  list-style: none;
  margin: auto;
  padding: 0;
  display: inline-table;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

#lnln-root .app-banners-list > li {
  display: table-cell;
  text-align: center;
  padding: 0 8px;
}

/* lp_1
================================================== */

#lnln-root .medico_lp_1-hero-title {
  margin: 0 23px;
  text-align: center;
}

#lnln-root .medico_lp_1-hero-lead {
  font-size: 13.5px;
  line-height: 1.4814
}

#lnln-root .medico_lp_1-hero-desc {
  margin: 15px 18px 0;
  font-weight: 700;
  font-size: 15px;
  line-height: 1.8;
}

#lnln-root .point {}
  
#lnln-root .point + .point:before {
  content: '';
  display: block;
  height: 60px;
  background: url(../../img/medico_lp/icon01.png) center center no-repeat;
  background-size: auto 23px;
}

#lnln-root .point-title {}

#lnln-root .point-desc {
  margin-top: 12px;
  font-size: 14px;
  line-height: 1.7858;
  color: #54544f;
}

#lnln-root .point-pict {
  text-align: center;
}

#lnln-root .point-note {
  color: #999;
}

/* lp_3
================================================== */

#lnln-root .qa {
  font-size: 14px;
  line-height: 1.7858;
  color: #54544f;
}

#lnln-root .qa + .qa:before {
  content: '';
  display: block;
  margin: 20px auto 25px;
  height: 2px;
  background: url(../../img/medico_lp/bg05.png) left top repeat-x;
  background-size: auto 100%;
}

#lnln-root .qa > dt {
  display: block;
  position: relative;
  padding-left: 50px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: #80d1e5;
}

#lnln-root .qa > dd {
  margin: 10px auto 20px;
}

#lnln-root .qa-icon {
  position: absolute;
  left: 0;
  top: 0;
}

#lnln-root .lnln-main {
  background-color: blue;
}

